import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import type { BodyType } from '@redux/features/routesPage/types/contextBlocksType'

import Button from '@base/buttons/Button'
import { UilArrowDown } from '@iconscout/react-unicons'
import { title4Styles, title5Styles } from '@styles/typography/title'
import Text from '@typography/Text'

type Props = {
  body: BodyType
  title: string
}

const Description: FC<Props> = ({ body, title }) => {
  const { t } = useTranslation('About with video')
  const [isShowButtonSM, setIsShowButtonSM] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const textContainerRef = useRef<HTMLDivElement>(null)
  const textContentRef = useRef<HTMLDivElement>(null)

  const handlerShowMore = () => {
    setIsShowMore(!isShowMore)
  }

  useEffect(() => {
    if (textContainerRef.current && textContentRef.current) {
      setIsShowButtonSM(textContentRef.current.scrollHeight > textContainerRef.current.clientHeight)
    }
  }, [])

  return (
    <Container>
      <TitleStyled>{title}</TitleStyled>
      <WrapText $isFullShow={isShowMore} ref={textContainerRef}>
        <Text size="m">
          <div dangerouslySetInnerHTML={{ __html: body.value }} ref={textContentRef} />
        </Text>
      </WrapText>
      {isShowButtonSM && (
        <ShowMore $isShow={isShowMore} color="primary" onClick={handlerShowMore} type="link">
          <Text size="l">{t(isShowMore ? 'showLess' : 'showMore')}</Text>
          <UilArrowDown height={20} width={20} />
        </ShowMore>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 20px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    background: ${p => p.theme.colors.backgroundLightContainer};
    padding: 20px 24px 0 24px;

    & > * {
      margin-bottom: 10px;
    }
  }
`

const TitleStyled = styled.h2`
  ${title4Styles};

  ${p => p.theme.mediaQueries.mobile} {
    ${title5Styles};
  }
`

const WrapText = styled.div<{ $isFullShow: boolean }>`
  ${p => p.theme.mediaQueries.mobileTablet} {
    transition: ${p => p.theme.transitions.default};
    max-height: ${p => (p.$isFullShow ? '1000px' : '170px')};
    overflow: hidden;
  }
  ${p => p.theme.mediaQueries.mobile} {
    max-height: ${p => (p.$isFullShow ? '1000px' : '75px')};
  }
`

const ShowMore = styled(Button)<{ $isShow?: boolean }>`
  &.ant-btn {
    display: none;

    ${p => p.theme.mediaQueries.mobileTablet} {
      display: flex;
      justify-content: flex-start;

      svg {
        margin-inline-start: 8px !important;
        transform: ${p => (p.$isShow ? 'rotate(180deg)' : 'rotate(0)')};
        transition: ${p => p.theme.transitions.default};
      }

      span {
        color: ${p => p.theme.colors.primary};
        font-size: 12px;
      }
    }
  }
`

export default Description
